import React from "react"

// Libraries
import AOS from "aos"
import { AnchorLink } from "gatsby-plugin-anchor-links"

// Styles
import "assets/globalStyles/reset.css"
import GlobalStyles from "assets/globalStyles/"
import "aos/dist/aos.css"

// Components
import Menu from "components/menu"
import Footer from "components/footer"

class Layout extends React.Component {
  componentDidMount = () => {
    AOS.init({
      duration: 600,
      once: true,
    })
  }

  focusMainContent = () => {
    document.getElementById("mainContent").focus();
  }

  render = props => (
    <React.Fragment>
      <GlobalStyles />
      <AnchorLink
        to={this.props.location.pathname + "#mainContent"}
        title="Skip to Main Content"
        className="skiplink"
        id="skip" 
        stripHash 
        onAnchorLinkClick={() => this.focusMainContent()}
      />
      { this.props.location.pathname.indexOf('/regional-pcr-testing') !== 0 &&
        <Menu
          ticker="CARB-X awards funding to Visby Medical for gonorrhea, antibiotic susceptibility test"
          labelDescription="Read more about CARB-X awards funding to Visby Medical for gonorrhea, antibiotic susceptibility test."
          url="/news/carb-x-awards-funding-to-visby-medical-for-gonorrhea-antibiotic-susceptibility-test/"
        />
      }
      <main><div id="mainContent" tabIndex="-1" role="none"></div>{this.props.children}</main>
      {this.props.location.pathname.indexOf('/regional-pcr-testing') !== 0 &&
        <Footer />
      }
    </React.Fragment>
  )
}

export default Layout
