import React from "react"

// Libraries
import { Link } from "gatsby"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

// Icons
import Logo from "assets/icons/logo-tm.inline.svg"

// Icons
import LinkedIn from "assets/icons/logo-linked-in.inline.svg"
import Twitter from "assets/icons/logo-twitter.inline.svg"
import Facebook from "assets/icons/logo-facebook.inline.svg"
import YouTube from "assets/icons/logo-youtube.inline.svg"

const StyledContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const StyledFooter = styled.footer`
  padding: 60px 0;
  background-color: ${colors.charcoal};
  color: ${colors.white};

  ${breakpoint.medium`
    padding: 124px 0;
  `}

  .msg-error {
      font-size: 17px;
      font-weight: 500;
      color: ${colors.orange};
      padding-left: 12px!important;
  }

  .footer__column--logo {
  		margin-left: -12px;
  
    svg {
      * {
        fill: ${colors.white};
        max-width: 240px;
        margin-left: -12px;
      }
    }
  }

  .footer__column {
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &--info {
      font-size: 16px;
      line-height: 24px;
    }

    ${breakpoint.medium`
      width: auto;

      &--logo {
        width: 100%;
        margin-left: -15px;
      }

      &--form {
        width: 100%;
        order: 0;
      }

      &--sitemap {
        columns: 260px 2;
        order: 0;
      }

      &--info {
        width: 220px;
      }
      
      &--address {
        width: 180px;
      }
      
    `}

    .title {
      color: ${colors.purpleLight};
      margin-bottom: 10px;
    }

    .footer__copy {
      font-size: 0.7rem;
      line-height: 1.1rem;
    }

  }

	.footer__column--sitemap a:hover {
		color: ${colors.orange};
	}

	.footer__column--info a:hover {
		color: ${colors.orange};
	}
  
 .social-links {
    display: flex;
    align-items: center;
    margin: 10px 0;

    .social-link {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }

      a {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${colors.grayDark};
        border-radius: 50%;

        &:hover {
          background-color: ${colors.grayMedium};
        }
      }
    }
  }

	#topButton {
		display: ${props => props.showTopButton ? "block" : "none"};
		position: fixed;
		bottom: 15px;
		right: 15px;
		z-index: 800;
		cursor: pointer;
		border-radius: 50%;
		width: 50px;
		height: 50px;
		background-color: ${colors.purpleDark};
		opacity: 0.8;
		border: 1px solid ${colors.white};
		
	  ${breakpoint.medium`
		bottom: 30px;
		right: 40px;
	  `}

	}

	#topButton:hover {
		opacity: 1;
	}
	
    .caret {
		border: solid ${colors.white};
		border-width: 0 6px 6px 0;
		display: inline-block;
		padding: 8px;
		margin-top: 16px;
    }
    
    .up{
	  transform: rotate(-135deg);
	  -webkit-transform: rotate(-135deg);
    }

`
class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lastScrollPosition: 0,
      displayTopButton: false
    }
  }

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll)
 	document.querySelector("body").style.scrollBehavior="initial"
 	document.querySelector("html").style.scrollBehavior="initial"
 }
 
  handleScroll = () => {
    const scrollPosition = window.scrollY

		this.setState({
		  lastScrollPosition: scrollPosition,
		})

	  if (this.state.lastScrollPosition > 900) {
			  this.setState({
				displayTopMenu: true,
			})
			document.querySelector("body").style.scrollBehavior="smooth"
			document.querySelector("html").style.scrollBehavior="smooth"
		} else  {
			  this.setState({
				displayTopMenu: false,
			})
		}
  }
  
 goBackToTop() {
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
  document.getElementById('gatsby-focus-wrapper').focus();
}

  render = () => (
    <StyledFooter showTopButton={this.state.displayTopMenu}>
      <StyledContainer>
        <div className="footer__column footer__column--logo">
          <Logo />
        </div>
        <ul className="footer__column footer__column--sitemap">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/sexual-health-test/">Sexual Health Test</Link>
          </li>
          <li>
            <Link to="/respiratory-health-test/">Respiratory Health Test</Link>
          </li>
          <li>
            <Link to="/about-us/">About Us</Link>
          </li>
          <li>
            <Link to="/news/">News</Link>
          </li>
          <li>
            <Link to="/faq/">FAQ</Link>
          </li>
          <li>
            <Link to="/how-to-buy/">How to Buy</Link>
          </li>
          <li>
            <Link to="/contact/">Contact</Link>
          </li>
          <li>
            <Link to="/join-our-list/">Join our List</Link>
          </li>
          <li>
            <Link to="/legal/">Legal</Link>
          </li>
          <li>
            <Link to="/careers/">Careers</Link>
          </li>
          <li>
            <Link to="/accessibility/">Accessibility</Link>
          </li>
        </ul>

        <div className="footer__column footer__column--info footer__column--address">
          <p className="title">Headquarters</p>
          <p>3010 North First Street<br />
            San Jose, CA 95134
          </p>
        </div>
        <div className="footer__column footer__column--info">
          <p className="title">Connect</p>
          <ul className="social-links">
            <li className="social-link">
              <a
                href="https://www.linkedin.com/company/visbymedical/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
                <LinkedIn />
              </a>
            </li>
            <li className="social-link">
              <a
                href="https://twitter.com/visbymedical"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
              >
                <Twitter />
              </a>
            </li>
            <li className="social-link">
              <a
                href="https://www.facebook.com/visbymedical/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <Facebook />
              </a>
            </li>
            <li className="social-link">
              <a
                href="https://www.youtube.com/channel/UCODvhhPem7t1wJXlWNbgpLg"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="YouTube"
              >
                <YouTube />
              </a>
            </li>
          </ul>
          <p className="footer__copy">©{new Date().getFullYear()} Visby Medical, Inc. All&nbsp;rights&nbsp;reserved.<br />
          Visby&nbsp;Medical and the Visby&nbsp;Medical logo are
          registered trademarks of Visby&nbsp;Medical, Inc.</p>
        </div>
      </StyledContainer>
      <button id="topButton" aria-label="Back to top" onClick={() => this.goBackToTop()}><span className="caret up"></span></button>
    </StyledFooter>
  )
}

export default Footer
