/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "@fontsource/poppins/400.css" 
import "@fontsource/poppins/600.css" 
import "@fontsource/poppins/700.css" 
import "@fontsource/poppins/900.css" 


const transitionDelay = 0

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    )
  }
  document.getElementById("gatsby-focus-wrapper").focus();
  return false
}