// Colors:start
export const colors = {
  orangeLight: "#FFDCB6",
  orange: "#FF926E",
  purpleLight: "#A699C1",
  purpleLightNew: "#B694DA",
  purpleLightBgNew: "#e2e0e7",
  purpleDark: "#8955C6",
  purpleMain: "#825DC7",
  purpleLightBg: "#D3C7EA",
  black: "#000",
  blackMedi: "#253746",
  charcoal: "#333",
  gray: "#d3d3d5",
  white: "#FFF",
  grayLight: "#F6F6F6", 
  grayNew: "#D0D0CE", 
  graySelect: "#757575",
  grayMedium: "#8e8c8c",
  grayDark: "#505050",
  green: "#72d084",
  greenLight: "#C5E86C",
  red: "#FF585D",
  blue: "#71DBD4"
}
// Colors:end

// Fonts:start
export const fonts = {
  primary: "Poppins",
}
// Fonts:end

// Breakpoints:start
export const breakpoints = {
  xs: "600",
  small: "768",
  medium: "1024",
  large: "1200",
  extraLarge: "1440",
  xxl: "1600",
  wide: "2000",
  xwide: "2400"
}
// Breakpoints:end
