import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

const StyledContainer = styled.div`
  max-width: 1100px;
  width: 100%;
  padding: 0 28px;
  margin: 0 auto;

  ${breakpoint.medium`
    padding: 0 60px;
  `}

  ${breakpoint.extraLarge`
    padding: 0;
  `}
`

export default StyledContainer
