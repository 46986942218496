import { createGlobalStyle } from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors, fonts } from "utils/variables/"


const GlobalStyles = createGlobalStyle`

  .no-scroll {
    overflow: hidden!important;
  }
 
  body {
    padding-top: 79px;
    color: ${colors.charcoal};
    font-family: ${fonts.primary};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;

    ${breakpoint.medium`
      padding-top: 87px;
      font-size: 21px;
    `}

    * {

      &:focus {
        //outline: 0;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: ${fonts.primary};
      font-weight: 700;
      line-height: 1.2em;
    }

    h1 {
      font-size: 36px;
      font-weight: 700;
      letter-spacing: -1px;
      color: ${colors.charcoal};

      ${breakpoint.medium`
        font-size: 46px;
      `}
      
    }

    h2 {
      font-size: 32px;
      font-weight: 700;

      ${breakpoint.medium`
        font-size: 42px;
      `}
    }

    h3 {
      font-size: 17px;
      font-weight: 700;

      ${breakpoint.medium`
        font-size: 21px;
      `}
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    button {
      padding: 0;
      margin: 0;
      border: 0;
      font-family: inherit;
      font-size: inherit;
      font-weight: 400;
      color: inherit;
      background: none;
      box-shadow: 0;
      cursor: pointer;
    }

    img,
    svg {
      max-width: 100%;
      height: auto;
      display: inline-block;
    }

	sup {
		font-size: 0.6rem;
		vertical-align: super;
		font-family: serif;
		position: relative;
		top: -0.3em;
		left: 0.1em;
	}
    
    select { 
      font-family: ${fonts.primary};
    }

  .grecaptcha-badge { visibility: hidden; }
      
  .skiplink {
    color: ${colors.charcoal};
    background-color: ${colors.white};
    padding: 1px 10px;
    position: absolute;
    left: 0.3rem;
    transform: translateY(-300px);
    transition: transform 325ms ease-in;
    font-size: 13px;
    font-weight: 600;
        
     &:focus {
        transform: translateY(-114px);
        z-index: 1001;

        ${breakpoint.small`
          transform: translateY(-124px);
        `}
      }
    }

    #pi_tracking_opt_in_div {
      position: fixed;
      display: flex;
      flex-flow: wrap;
      max-width: 1300px;
      text-align: left;
      background: ${colors.white};
      color: ${colors.charcoal};
      font-size: 1rem;
      padding: 2rem 1.6rem 1.8rem 2rem;
      z-index: 1000;
      width: 92vw;
      border-radius: 1.2rem;
      box-shadow: rgba(0, 0, 0, 0.33) 0px 8px 28px;
      line-height: 1.4em;
      bottom: 1vh;
      left: 50%;
      transform: translate(-50%, -50%);
      row-gap: 1em;
      column-gap: 0.8em;      
      justify-content: center;

      ${breakpoint.small`
      	flex-wrap: nowrap;
          `}
    }

    #pi_tracking_opt_in_yes, #pi_tracking_opt_in_no {
      width: 6em;
      height: 2.2em;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 1em 2em;
      border-radius: 5em;
      font-size: 1rem;
      line-height: 1.2em;
      font-weight: 600;
      box-sizing: border-box;
      margin-right: 0!important;
    }

    #pi_tracking_opt_in_yes { visibility: hidden;}

    #pi_tracking_opt_in_yes::after { 
        visibility: visible; 
        content:"Accept";
        background-color: ${colors.orange};
        color: ${colors.white};
        width: 6em;
        height: 2.2em;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 1em 2em;
        border-radius: 5em;
        font-size: 1rem;
        line-height: 1.2em;
        font-weight: 600;
        box-sizing: border-box;
    }

    #pi_tracking_opt_in_no { visibility: hidden; }

    #pi_tracking_opt_in_no::after { 
        visibility: visible; 
        content:"Decline";
        border: 1px solid ${colors.orange};
        color: ${colors.orange};
        background-color: ${colors.white};
        width: 6em;
        height: 2.2em;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 1em 2em;
        border-radius: 5em;
        font-size: 1rem;
        line-height: 1.2em;
        font-weight: 600;
        box-sizing: border-box;
    }

    #pi_tracking_opt_in_div a:hover, a:hover::after {
      color: ${colors.charcoal}!important;
  }

  .visibleBlock {
    display: block;
  }

  .invisible {
    display: none;
  }

`

export default GlobalStyles
