import React from "react"

// Libraries 
import { Link } from "gatsby"
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import { ButtonLink } from "components/buttons"

// Icons
import Logo from "assets/icons/logo-tm.inline.svg"

const StyledMenu = styled.nav`
  width: 100vw;
  height: ${props => props.showBanner ? "120px!important" : "80px"};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: ${colors.white};
  border-bottom-width: 0;
  transform: ${props =>
    props.isVisible ? "translateY(0)" : "translateY(-110%)"};
  transition: all 0.3s;
  z-index: 900;
  overflow: visible;
  border-bottom: 1px solid ${colors.grayLight};

  ${breakpoint.medium`
    height: 88px;
  `}

  ${Container} {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 23px;
    border: none !important;
  }
  
  .menu__first-section {
	margin-top: ${props => (props.showBanner ? "40px" : "0")};
    display: flex;
    align-items: center;
    margin-top: ${props => (props.open ? "-40px" : "40px")}; 

      ${breakpoint.medium`
			  margin-top: ${props => (props.showBanner ? "40px" : "0")};
      `}


    .menu__toggler {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-right: 15px;
      z-index: 901;

      ${breakpoint.medium`
        display: none;
      `}

      span {
        width: 16px;
        height: 2px;
        position: relative;
        margin-bottom: 4px;
        background-color: ${props =>
    props.open ? colors.white : colors.black};
        transition: all 0.3s ease;

        &:first-child {
			top: ${props => (props.open ? "6px" : "0")};
			transform: ${props =>
    props.open ? "rotate(45deg)" : "rotate(0deg)"};
        }

        &:nth-child(2) {
          opacity: ${props => (props.open ? "0" : "1")};
        }

        &:last-child {
          top: ${props => (props.open ? "-6px" : "0")};
          margin-bottom: 0;
          transform: ${props =>
    props.open ? "rotate(-45deg)" : "rotate(0deg)"};
        }
      }
    }

    .menu__logo {
      width: 150px;
      position: relative;
      display: flex;
      align-items: flex-start;
      z-index: 901;
	  margin-left: -7px;
	  
      ${breakpoint.medium`
        width: 240px;
	    margin-left: -11px;
      `}

      svg {
        * {
          transition: all 0.3s ease;
          fill: ${props => (props.open ? colors.white : colors.black)};
        }
      }
    }
  }

  .menu__second-section {
    height: 100%;
    display: flex;
    align-items: center;
	  margin-top: ${props => props.showBanner ? "40px" : "0px"};

    .menu__content {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      align-items: center;
      padding: 0 28px;
      background-color: ${colors.purpleDark};
      display: ${props => (props.open ? "flex" : "none")};
      opacity: ${props => (props.open ? "1" : "0")};
      visibility: ${props => (props.open ? "visible" : "hidden")};
      transition: all 0.3s ease;
      z-index: 900;
      white-space: nowrap;

      ${breakpoint.medium`
        width: auto;
        height: auto;
        position: relative;
        padding: 0;
        background: none;
        display: flex;
        opacity: 1;
        visibility: visible;
      `}

      ul {
      	margin-top: 0;
      
        ${breakpoint.medium`
          display: flex;
        `}
      }

      li {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        ${breakpoint.medium`
          margin: 0 20px 0 0;

          &.link__order {
            display: none;
          }
        `}

        ${breakpoint.large`
          margin-right: 40px;
        `}
      }

      .hide-item {
        display: none;
  
          ${breakpoint.medium`
            display: block;
            `}
      }
  
      a {
        color: ${colors.white};
        font-size: 27px;
        font-weight: 600;
        transition: all 0.3s;
        
        &:hover,
        &:active {
          color: ${colors.orange};
        }

        ${breakpoint.medium`
          color: ${colors.black};
          font-size: 16px;
          font-weight: 600;
        `}
        }
     	
      a[href="/"] { 
        display:block; 
      
        ${breakpoint.medium`
          display: none;
        `}
      }
      a[href="/how-to-buy/"] { 
        display:block; 
      
        ${breakpoint.medium`
          display: none;
        `}
      }
    
      a[class~="order"] { display: flex; }

      li.dropdown {
        display: block;

        ${breakpoint.medium`
          display: inline-block;
        `}
      }

      .dropdown:hover .dropdown-content {
        ${breakpoint.medium`
          display: ${props => (props.open ? "none" : "block")};
        `}
      }

      .dropdown:focus-within .dropdown-content {
        ${breakpoint.medium`
          display: ${props => (props.open ? "none" : "block")};
        `}
      }


      .dropdown-content {
        display: block;
        position: initial;
        z-index: 1;
        line-height: 1.6rem!important;
        padding-left: 23px;    

        ${breakpoint.medium`
          display: none;
          position: absolute;
          background-color: ${colors.white};
          box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
          min-width: 150px;
          padding: 10px 12px 8px 10px;
          margin-top: 2px;
        `}
      }

      .dropdown-content a {
        padding: 5px;
        font-size: 22px!important;
        line-height: 1.2em;

        ${breakpoint.medium`
          font-size: 14px!important;
          line-height: 3em;
          padding: 10px;
        `}
      }
      
		.nolink-menu {
			color: ${colors.white};
			font-size: 27px;
			font-weight: 600;
			margin-top: 0px;
			margin-bottom: 5px;

      ${breakpoint.medium`
        color: ${colors.black};
        font-size: 16px;
        font-weight: 600;
        margin-top: 2px;
        margin-bottom: 0px;
        padding: 0 2px;
      `}

        &:focus { outline: 0; }

      }
      
    }
    }

    .order {
      max-width: 169px;
      width: auto;
      height: 30px;

      ${breakpoint.small`
        height: 45px;
      `}
    }
  }
  
  
  .topbanner {
	display: ${props => props.showBanner ? "flex" : "none"};
    position: fixed;
    background: ${colors.purpleDark};
    top: 0px;
    width: 100%;
    height: 40px;
	font-size: 13px;
    font-weight: 400;
    text-align: center;
    color: ${colors.white};
    z-index: 900;
	justify-content: center;
	align-items: center;
	line-height: 1.1em; 

    ${breakpoint.medium`
      font-size: 14px;
      line-height: 1.5em; 
    `}

	a {
		color: ${colors.orange};

		  &:hover {
			color: ${colors.black};
		  }

		  &:focus {
        outline: solid 1px ${colors.white};
        }

      }

	a:after {
		content: "→";
		padding-left: 2px;
		color: ${colors.white};
		font-weight: 100;
	}
}

.topTextSpan {
	padding-inline: 5px;
  
		&::after {
			content:" ";

			${breakpoint.small`
					padding-right: 10px;
			`}

			${breakpoint.medium`
					padding-right: 20px;
			`}

		}

}
`

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMenuOpen: false,
      isMenuVisible: true,
      isBannerToShow: true
    }
  }

  componentDidMount = (props) => {
  
    window.addEventListener('resize', this.resizeHeader);

    if (this.state.isBannerToShow) {
      if (document.body.clientWidth < 1024) {
        document.querySelector("body").style.paddingTop = "119px"
      } else {
        document.querySelector("body").style.paddingTop = "120px"
      }
    }
  }


  resizeHeader = () => {
    if (this.state.isBannerToShow) {
      if (document.body.clientWidth < 1024) {
        document.querySelector("body").style.paddingTop = "119px"
      } else {
        document.querySelector("body").style.paddingTop = "120px"
      }
    }
  }

  openMenu = () => {
    if (!this.state.isMenuOpen) {
      this.setState(
        {
          isMenuOpen: true,
        },
        () => {
          document.querySelector("html").classList.add("no-scroll")
          document.querySelector("body").classList.add("no-scroll")
        }
      )
    }
  }

  closeMenu = () => {
    if (this.state.isMenuOpen) {
      this.setState(
        {
          isMenuOpen: false,
        },
        () => {
          document.querySelector("html").classList.remove("no-scroll")
          document.querySelector("body").classList.remove("no-scroll")
        }
      )
    }
  }

  toggleMenu = () => {
    this.setState(
      prevState => ({
        isMenuOpen: !prevState.isMenuOpen,
      }),
      () => {
        if (this.state.isMenuOpen) {
          document.querySelector("html").classList.add("no-scroll")
          document.querySelector("body").classList.add("no-scroll")
        } else {
          document.querySelector("html").classList.remove("no-scroll")
          document.querySelector("body").classList.remove("no-scroll")
        }
      }
    )
  }

  render = () => (
    <StyledMenu
      open={this.state.isMenuOpen}
      isVisible={this.state.isMenuVisible}
      showBanner={this.state.isBannerToShow}
    >
      <div className="topbanner">
        <div className="topText" data-aos="fade-down" data-aos-easing="linear">
          <span className="topTextSpan">{this.props.ticker}</span>
          <Link to={this.props.url} aria-label={this.props.labelDescription}>Read&nbsp;more</Link>
        </div>
      </div>
      <Container>
        <div className="menu__first-section">
          <button
            type="button"
            className="menu__toggler"
            onClick={this.toggleMenu}
            title="Toggle Menu"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>

          <Link to="/" className="menu__logo" aria-label="Visby Medical" onClick={this.closeMenu}>
            <Logo />
          </Link>
        </div>

        <div className="menu__second-section">
          <div className="menu__content">
            <ul>
              <li>
                <Link
                  activeClassName="active"
                  to="/"
                  onClick={this.closeMenu}
                >
                  Home
                </Link>
              </li>
              <li className="dropdown">
                <div className="nolink-menu" role="button" tabIndex="0">
                  Products
                </div>
                <div className="dropdown-content">
                  <Link
                    to="/sexual-health-test/"
                    onClick={this.closeMenu}
                  >
                    Sexual Health Test
                  </Link>
                  <br />
                  <Link
                    to="/respiratory-health-test/"
                    onClick={this.closeMenu}
                  >
                    Respiratory Health Test
                  </Link>
                </div>
              </li>
              <li className="dropdown">
                <Link
                  to="/about-us/"
                  activeClassName="active"
                  onClick={this.closeMenu}
                >
                  About Us
                </Link>
                <div className="dropdown-content">
                  <div className="hide-item">
                    <AnchorLink to="/about-us/#our-story" title="Our Story" onClick={this.closeMenu} />
                    <br /></div>
                  <Link
                    to="/our-team/"
                    onClick={this.closeMenu}
                  >
                    Our Team
                  </Link>
                  <br />
                  <Link
                    to="/awards/"
                    onClick={this.closeMenu}
                  >
                    Awards
                  </Link>
                  <br />
                  <Link
                    to="/careers/"
                    onClick={this.closeMenu}
                  >
                    Careers
                  </Link>
                  <br />
                  <Link
                    to="/faq/"
                    onClick={this.closeMenu}
                  >
                    FAQ
                  </Link>
                </div>
              </li>
              <li className="dropdown">
                <Link
                  to="/news/"
                  activeClassName="active"
                  onClick={this.closeMenu}
                >
                  News
                </Link>
                <div className="dropdown-content">
                  <Link
                    to="/resources/media-coverage/"
                    onClick={this.closeMenu}
                  >
                    Media Coverage
                  </Link>
                  <br />
                  <div className="hide-item"><Link
                    to="/resources/press-kit/"
                    onClick={this.closeMenu}
                  >
                    Press Kit
                  </Link>
                  </div>
                </div>
              </li>
              <li className="dropdown">
                <div className="nolink-menu" role="button" tabIndex="0">
                  Insights
                </div>
                <div className="dropdown-content">
                  <Link
                    to="/resources/case-studies/"
                    onClick={this.closeMenu}
                  >
                    Case Studies
                  </Link>
                  <br />
                  <Link
                    to="/resources/publications/"
                    onClick={this.closeMenu}
                  >
                    Publications
                  </Link>
                  <br />
                  <Link
                    to="/resources/videos/"
                    onClick={this.closeMenu}
                  >
                    Videos
                  </Link><br />
                  <Link
                    to="/resources/viewpoints/"
                    onClick={this.closeMenu}
                  >
                    Viewpoints
                  </Link><br />
                  <Link
                    to="/resources/webinars/"
                    onClick={this.closeMenu}
                  >
                    Webinars
                  </Link>
                </div>
              </li>
              <li>
                <Link
                  to="/contact/"
                  activeClassName="active"
                  onClick={this.closeMenu}
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  activeClassName="active"
                  to="/how-to-buy/"
                  onClick={this.closeMenu}
                >
                  How to Buy
                </Link>
              </li>
            </ul>
          </div>

          <ButtonLink
            to="/how-to-buy/"
            className="order"
            activeClassName="active"
            backgroundcolor="primary"
          >
            How to Buy
          </ButtonLink>
        </div>
      </Container>
    </StyledMenu>
  )
}

export default Menu
